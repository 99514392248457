import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL
const HOA_URL = `${API_URL}/admin/dashboard`

const getDashboardData = () => {
    return axios
        .get(`${HOA_URL}`)
        .then((d: AxiosResponse) => d.data)
}

export { getDashboardData}