import { ID, Response } from '../../../../_metronic/helpers'

// response
export interface IResponse {
    status: boolean;
    message: string;
    data: any;
    error: any;
}

// listing
export type Guideline = {
    id?: ID
    request_item?: string
    snippet?: string
}

export type GuidelineQueryResponse = Response<Array<Guideline>>

export const initialUser: Guideline = {
    id: '',
    request_item: '',
    snippet: '',
}

// create
export type IGuidelineCreate = {
    request_item: string
    snippet: string,
}
export const GuidelineInitValues: IGuidelineCreate = {
    request_item: "",
    snippet: "",
}

// Update
export type IGuidelineUpdate = {
    id: string,
    request_item: string,
    snippet: string,
}
export const guidelineUpdateInitValues: IGuidelineUpdate = {
    id: "",
    request_item: "",
    snippet: "",
}
