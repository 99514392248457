// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'
import {useQueryResponsePagination} from '../../core/QueryResponseProvider'



type Props = {
  row: Row<User>
}

const CustomRow: FC<Props> = ({row}) => {
  const pagination:any = useQueryResponsePagination()
  return (<tr {...row.getRowProps()}>
    {row.cells.map((cell, i) => {
      if (cell.column.id === 'no') {
        return (
          <td {...cell.getCellProps()}>{pagination.from + cell.row.index}</td>
        )
      }else{
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          >
            {cell.render('Cell')}
          </td>
        )
      }
    })}
  </tr>)
}

export {CustomRow}
