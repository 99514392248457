import {FC} from 'react'
import { Link } from 'react-router-dom'

type Props = {
  property: any
}

const PropertyMemberInfoCell: FC<Props> = ({ property }) => (
  <>
    {property.member ? (
      <div className='d-flex align-items-center'>
        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
          <a href="#"><div className="symbol-label fs-3 bg-light-danger text-danger">{property.member.first_name?.substring(0, 1).toUpperCase()}</div></a>
        </div>
        <div className='d-flex flex-column'>
          <Link to={`/members/${property.member.id}/edit`} className='text-gray-800 text-hover-primary mb-1'>
            {property.member.first_name+' '+property.member.last_name}
          </Link><span>{property.member.email}</span>
        </div>
      </div>):(<>Not Assigned</>)
    }
  </>
)

export { PropertyMemberInfoCell }
