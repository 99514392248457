import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { HoaCreate } from "./HoaCreate";
import { HoaListWrapper } from "./hoa-list/HoaList";
import { HoaEdit } from "./HoaEdit";
import HoaDetails from "./hoa-details/HoaDetails";

const hoaBreadcrumbs: Array<PageLink> = [
    { title: 'Dashboard', path: '/dashboard', isSeparator: false, isActive: false },
    { title: '', path: '', isSeparator: true, isActive: false},
]
const hoaCreateBreadcrumbs: Array<PageLink> = [
    { title: 'List HOA', path: '/hoa', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const hoaEditBreadcrumbs: Array<PageLink> = [
    { title: 'List HOA', path: '/hoa', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const hoaDetailsBreadcrumbs: Array<PageLink> = [
    { title: 'List HOA', path: '/hoa', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const HoaPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={hoaBreadcrumbs}>List HOA</PageTitle>
                            <HoaListWrapper />
                        </>
                    }
                />
                <Route
                    path='create'
                    element={
                        <>
                            <PageTitle breadcrumbs={hoaCreateBreadcrumbs}>Create New HOA</PageTitle>
                            <HoaCreate />
                        </>
                    }
                />
                <Route
                    path=':id/edit'
                    element={
                        <>
                            <PageTitle breadcrumbs={hoaEditBreadcrumbs}>Edit HOA</PageTitle>
                            <HoaEdit />
                        </>
                    }
                />
                <Route
                    path=':id/details'
                    element={
                        <>
                            <PageTitle breadcrumbs={hoaDetailsBreadcrumbs}>Detail HOA</PageTitle>
                            <HoaDetails />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}
export { HoaPage }
