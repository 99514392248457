import clsx from "clsx";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import { FC, useEffect, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getGuideline, updateGuideline } from "./core/_requests";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IGuidelineUpdate, guidelineUpdateInitValues as initialValues } from "./core/_models";
import InputSkeleton from "../../common/Skeleton/InputSkeleton";
import { useThemeMode } from "../../../_metronic/partials";

const guidelineCreateSchema = Yup.object().shape({
    request_item: Yup.string().required('Request item is required'),
    snippet: Yup.string().required('Snippet is required'),
})
const GuidelineEdit: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false)
    const [btnloading, setBtnLoading] = useState(false)
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null)
    const { mode } = useThemeMode();
    // Guideline form   
    const { handleSubmit, touched, errors, getFieldProps, isSubmitting, isValid, setValues, setFieldValue, setFieldTouched, values } = useFormik<IGuidelineUpdate>({
        initialValues: initialValues,
        validationSchema: guidelineCreateSchema,
        onSubmit: async (values: IGuidelineUpdate, { setStatus, setSubmitting }: FormikHelpers<IGuidelineUpdate>) => {
            setBtnLoading(true)
            setError(null)
            try {
                values.snippet = `<div style="font-family: ${process.env.REACT_APP_CONTENT_FONT_FAMILY}; font-size: ${process.env.REACT_APP_CONTENT_FONT_SIZE};">${values.snippet}</div>`;
                const response: any = await updateGuideline(values);
                if (response.status == true) {
                    toast.success('Guideline updated successfully.');
                    navigate('/guidelines');
                }else{
                    navigate('/guidelines');
                }
            } catch (error) {
                setBtnLoading(false);
                // console.error('Failed to update guideline:', error);
                // setError('Failed to update guideline. Please try again.');
            }
        },
    })

    useEffect(() => {
        const fetchGuideline = async () => {
            setLoading(true)
            try {
                const response: any = await getGuideline(id);
                if (response) {
                    setValues({
                        id: response.data.id,
                        request_item: response.data.request_item,
                        snippet: response.data.snippet,
                    })
                    // setAcknowledgement(response.data.acknowledgements || []);
                }else{
                    navigate('/guidelines');
                }
            } catch (error) {
                setLoading(false)
            } finally {
                setLoading(false)
            }
        };
        fetchGuideline();
    }, [id]);
    return (
        <>
            {mode === 'dark' && (
                <style>{`
                    .ck.ck-editor__main>.ck-editor__editable{
                        background-color: #1C1C1C !important; 
                    }
                    `}
                </style>)
            }
            {/* Guideline */}
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_guideline_edit'
                    aria-expanded='true'
                    aria-controls='kt_guideline_edit'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Edit Guideline</h3>
                    </div>
                    <div className='card-title m-0'>
                    </div>
                </div>
                <div id='kt_guideline_edit'>
                    <form onSubmit={handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                            <div className="row mb-6">
                                {loading ? (<InputSkeleton rows={1} columns={1} colsWidth={8} />) : (
                                    <div className="col-lg-8 fv-row">

                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Request Item</label>
                                        <input
                                            type='text'
                                            placeholder='Request Item'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': touched.request_item && errors.request_item },
                                                { 'is-valid': touched.request_item && !errors.request_item }
                                            )}
                                            {...getFieldProps("request_item")}
                                        />
                                        {touched.request_item && errors.request_item && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.request_item}</div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {loading ? (<InputSkeleton rows={1} columns={1} colsWidth={8} height={200} mt={10} />) : (
                                    <div className="col-lg-8 fv-row mt-15">

                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Snippet</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={values.snippet}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setFieldValue('snippet', data);
                                            }}
                                            onBlur={() => setFieldTouched('snippet')}
                                        />
                                        {touched.snippet && errors.snippet && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.snippet}</div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <Link to={"/guidelines"} className='btn btn-secondary me-3'>Cancel</Link>
                            <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                                {!btnloading && 'Update Guideline'}
                                {btnloading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export { GuidelineEdit }