import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { GuidelineEdit, GuidelineCreate, GuidelineListWrapper } from"../guideline/index"

const guidelineBreadcrumbs: Array<PageLink> = [
    { title: 'Dashboard', path: '/dashboard', isSeparator: false, isActive: false },
    { title: '', path: '', isSeparator: true, isActive: false },
]
const guidelineCreateBreadcrumbs: Array<PageLink> = [
    { title: 'List Guideline', path: '/guidelines', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const guidelineEditBreadcrumbs: Array<PageLink> = [
    { title: 'List Guideline', path: '/guidelines', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const GuidelinePage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={guidelineBreadcrumbs}>List Guideline</PageTitle>
                            <GuidelineListWrapper />
                        </>
                    }
                />
                <Route
                    path='create'
                    element={
                        <>
                            <PageTitle breadcrumbs={guidelineCreateBreadcrumbs}>Create New Guideline</PageTitle>
                            <GuidelineCreate />
                        </>
                    }
                />
                <Route
                    path=':id/edit'
                    element={
                        <>
                            <PageTitle breadcrumbs={guidelineEditBreadcrumbs}>Edit Guideline</PageTitle>
                            <GuidelineEdit />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}
export { GuidelinePage }
    