import {FC} from 'react'
import { Property } from '../../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  property: Property
}

const PropertyInfoCell: FC<Props> = ({ property }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/property/${property.id}/edit`} className='text-gray-800 text-hover-primary mb-1'>
        {property? `${property.address || ""}` +
          (property.city ? `, ${property.city}` : "") +
          (property.plat ? `, ${property.plat}` : "") +
          (property.state ? `, ${property.state}` : "") +
          (property.zip ? ` ${property.zip}` : ""):""}
      </Link>
    </div>
  </div>
)

export { PropertyInfoCell }
