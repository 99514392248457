import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { HoaPage } from '../modules/hoa/HoaPage'
import { GuidelinePage } from '../modules/guideline/GuidelinePage'
import { BoardPage } from '../modules/board/BoardPage'
import { useAuth } from '../modules/auth'
import { AcknowledgementPage } from '../modules/acknowledgement/AcknowledgementPage'
import { PropertyPage } from '../modules/property/PropertyPage'


const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const MemberPage = lazy(() => import('../modules/member/MembersPage'))
  const RequestPage = lazy(() => import('../modules/request/RequestPage'))
  // const BoardPage = lazy(() => import('../modules/board/BoardPage'))
  // const AcknowledgementPage = lazy(() => import('../modules/acknowledgement/AcknowledgementPage'))
  const { currentUser }: any = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* {(currentUser.role.includes('administrator') || currentUser.role.includes('hoa')) && */}
          <Route
            path='members/*'
            element={
              <SuspensedView>
                <MemberPage />
              </SuspensedView>
            }
          />
       {/*  } */}
       {/*  {currentUser.role.includes('administrator') && */}
          <>
            <Route
              path='hoa/*'
              element={
                <SuspensedView>
                  <HoaPage />
                </SuspensedView>
              }
            />
            
          </>
        {/* } */}

        <Route
          path='staff/*'
          element={
            <SuspensedView>
              <BoardPage />
            </SuspensedView>
          }
        />

        {/* {currentUser.role.includes('hoa') && */}
          <>
            <Route
              path='guidelines/*'
              element={
                <SuspensedView>
                  <GuidelinePage />
                </SuspensedView>
              }
            />

            <Route
              path='acknowledgement/*'
              element={
                <SuspensedView>
                  <AcknowledgementPage />
                </SuspensedView>
              }
            />
          </>
        {/* } */}
        <Route
          path='request/*'
          element={
            <SuspensedView>
              <RequestPage />
            </SuspensedView>
          }
        />
        <Route
          path='property/*'
          element={
            <SuspensedView>
              <PropertyPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
