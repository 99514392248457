import Skeleton from "react-loading-skeleton"

function InputSkeleton({ rows = 2, columns = 2, height = 40, width = 550, colsWidth = 6, mt = 30, padding ="p-9" }) {
    return (
        <div className={padding}>
            {Array(rows).fill(0).map((_, rowIndex) => (
                <div key={rowIndex} className={`row mb-6 ${rowIndex > 0 ? `mt-${mt}` : 'mt-10'}`}>
                    {Array(columns).fill(0).map((_, colIndex) => (
                        <div key={colIndex} className={`col-lg-${colsWidth} fv-row`}>
                            <Skeleton height={height} width={width} className='mb-4' />
                        </div>
                    ))}

                </div>
            ))}
        </div>
    )
}

export default InputSkeleton