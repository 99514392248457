import TableSkeleton from "../../../common/Skeleton/TableSkeleton"

function HoaAcknowledgement({ hoaDetails, loading }) {
    return (
        <div className="card mb-5 mb-xl-8">
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0 d-flex justify-content-between'>
                    <h3 className='fw-bolder m-0'>Acknowledgement</h3>
                </div>
            </div>
            <div className="card-body py-3">
                <div className="table-responsive">
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                            <tr className="fw-bold text-muted">
                                <th className="min-w-150px">Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                (
                                    <TableSkeleton rows={5} columns={1} height={35} />
                                ) :
                                hoaDetails.acknowledgements && hoaDetails.acknowledgements.length > 0 ? (
                                    hoaDetails.acknowledgements.map((ack, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-dark text-hover-primary fs-6" dangerouslySetInnerHTML={{ __html: ack?.title }}></p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                ) : (
                                    <tr>
                                        <td colSpan={7}>
                                            <div className='d-flex fw-bold text-center fs-6 mt-5 w-100 align-content-center justify-content-center'>
                                                No records have been added yet.
                                            </div>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default HoaAcknowledgement