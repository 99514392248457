import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { PropertyCreate } from "./PropertyCreate";
import { PropertyEdit } from "./PropertyEdit";
import { PropertyListWrapper } from "./property-list/PropertyList";
import { PropertyUpload } from "../property-upload/PropertyUpload";
// import { PropertyListWrapper } from "./property-list/PropertyList";
// import { PropertyEdit, PropertyCreate, PropertyListWrapper } from "../property/index"

const propertyBreadcrumbs: Array<PageLink> = [
    { title: 'Dashboard', path: '/dashboard', isSeparator: false, isActive: false },
    { title: '', path: '', isSeparator: true, isActive: false },
]
const propertyCreateBreadcrumbs: Array<PageLink> = [
    { title: 'List Property', path: '/property', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const guidelineEditBreadcrumbs: Array<PageLink> = [
    { title: 'List Property', path: '/property', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const PropertyPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={propertyBreadcrumbs}>List Property</PageTitle>
                            <PropertyListWrapper />
                        </>
                    }
                />
                <Route
                    path='create'
                    element={
                        <>
                            <PageTitle breadcrumbs={propertyCreateBreadcrumbs}>Create New Property</PageTitle>
                            <PropertyCreate />
                        </>
                    }
                />
                <Route
                    path=':id/edit'
                    element={
                        <>
                            <PageTitle breadcrumbs={guidelineEditBreadcrumbs}>Edit Property</PageTitle>
                            <PropertyEdit />
                        </>
                    }
                />
                <Route
                    path='import'
                    element={
                        <>
                            <PageTitle breadcrumbs={guidelineEditBreadcrumbs}>Import Property</PageTitle>
                            <PropertyUpload />
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}
export { PropertyPage }
