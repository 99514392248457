import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { BoardCreate } from "./BoardCreate";
import { BoardListWrapper } from "./board-list/BoardList";
import { BoardEdit } from "./BoardEdit";
import { RoleAssign } from "./RoleAssign";
import { RolesList } from "./RolesList";

const boardBreadcrumbs: Array<PageLink> = [
    { title: 'Dashboard', path: '/dashboard', isSeparator: false, isActive: false },
    { title: '', path: '', isSeparator: true, isActive: false},
]
const boardCreateBreadcrumbs: Array<PageLink> = [
    { title: 'View Roles', path: '/staff', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const boardEditBreadcrumbs: Array<PageLink> = [
    { title: 'View Roles', path: '/staff', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]

const boardAssignRoleBreadcrumbs: Array<PageLink> = [
    { title: 'View Roles', path: '/staff', isSeparator: false, isActive: false, },
    { title: '', path: '', isSeparator: true, isActive: false, },
]
const BoardPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={boardBreadcrumbs}>View Roles</PageTitle>
                            <BoardListWrapper />
                        </>
                    }
                />
                <Route
                    path='create'
                    element={
                        <>
                            <PageTitle breadcrumbs={boardCreateBreadcrumbs}>Create New Staff</PageTitle>
                            <BoardCreate />
                        </>
                    }
                />
                <Route
                    path=':id/edit'
                    element={
                        <>
                            <PageTitle breadcrumbs={boardEditBreadcrumbs}>Edit Staff</PageTitle>
                            <RoleAssign />
                        </>
                    }
                />
                <Route
                    path='assign-role/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={boardAssignRoleBreadcrumbs}>Assign Member Role</PageTitle>
                            <BoardEdit />
                        </>
                    }
                />

                <Route
                    path='roles'
                    element={
                        <>
                            <PageTitle breadcrumbs={boardAssignRoleBreadcrumbs}>Roles List</PageTitle>
                            <RolesList />
                        </>
                    }
                />

            </Route>
        </Routes>
    )
}
export { BoardPage }
