import axios, { AxiosResponse } from "axios";
import { IHoaCreate, IHoaResponse, IHoaUpdate } from "./_models";
import { HoaQueryResponse } from "../../hoa/core/_models";
import { ID } from "../../../../_metronic/helpers";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL
const HOA_URL = `${API_URL}/admin/hoa`

const getUsers = (query: string): Promise<HoaQueryResponse> => {
    return axios
        .get(`${HOA_URL}?${query}`)
        .then((d: AxiosResponse<HoaQueryResponse>) => d.data)
}
async function createHoa(payload: IHoaCreate): Promise<IHoaResponse | null> {
    try {
        const response: AxiosResponse<IHoaResponse> = await axios.post(HOA_URL, payload);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to create Hoa: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function getHoa(id: string | undefined): Promise<IHoaResponse | null> {
    try {
        const response: AxiosResponse<IHoaResponse> = await axios.get(`${HOA_URL}/${id}`);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to get Hoa: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function gethoaDetails(id: string | undefined): Promise<IHoaResponse | null> {
    try {
        const response: AxiosResponse<IHoaResponse> = await axios.get(`${HOA_URL}/details/${id}`);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to get details Hoa: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function updateHoa(payload: IHoaUpdate): Promise<IHoaResponse | null> {
    try {
        const response: AxiosResponse<IHoaResponse> = await axios.patch(`${HOA_URL}/${payload.id}`, payload);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to update Hoa: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function deleteHoa(id: ID): Promise<IHoaResponse | null> {
    try {
        const response: AxiosResponse<IHoaResponse> = await axios.delete(`${HOA_URL}/${id}`);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to delete Hoa: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}

export { getUsers, createHoa, getHoa, gethoaDetails, updateHoa, deleteHoa }