
import axios, { AxiosResponse } from "axios";
import { IAcknowledgementCreate, IResponse, IAcknowledgementUpdate, AcknowledgementQueryResponse } from "./_models";
import { ID } from "../../../../_metronic/helpers";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL
const ACKNOWLEDGEMENT_URL = `${API_URL}/admin/acknowledgements`

const getAcknowledgementsList = (): Promise<AcknowledgementQueryResponse> => {
    return axios
        .get(`${ACKNOWLEDGEMENT_URL}`)
        .then((d: AxiosResponse<AcknowledgementQueryResponse>) => d.data)
}
async function createAcknowledgement(payload: IAcknowledgementCreate): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.post(ACKNOWLEDGEMENT_URL, payload);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to create acknowledgement. Please try again.");
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function getAcknowledgement(id: number): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.get(`${ACKNOWLEDGEMENT_URL}/${id}`);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to get acknowledgement : " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Access denied or Acknowledgement not found');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function updateAcknowledgement(payload: IAcknowledgementUpdate): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.patch(`${ACKNOWLEDGEMENT_URL}/${payload.id}`, payload);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to update acknowledgement: " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Access denied or Acknowledgement not found.');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function deleteAcknowledgement(id: ID): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.delete(`${ACKNOWLEDGEMENT_URL}/${id}`);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to delete acknowledgement:" + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
export { getAcknowledgementsList, createAcknowledgement, getAcknowledgement, updateAcknowledgement, deleteAcknowledgement }
