import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import { GuidelineListHeader } from './components/GuidelineListHeader'
import { GuidelineTable } from './table/GuidelineTable'
import {KTCard} from '../../../../_metronic/helpers'

const GuidelineList = () => {
  return (
    <>
      <KTCard>
        <GuidelineListHeader />
        <GuidelineTable />
      </KTCard>
    </>
  )
}

const GuidelineListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <GuidelineList />
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { GuidelineListWrapper }
