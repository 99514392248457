// declare module '@yaireo/tagify';
// declare module '@yaireo/tagify/dist/react.tagify';

export const REQUEST_COLOR_DATA = {
    "submitted":{
        color:"grey",
        title: "Submitted"
    },
    "in_review":{
        color:"warning",
        title: "Under Review"
    },
    "hoa_reviewed":{
        color:"primary",
        title: "Ready for Board Review"
    },
    "needs_discussion":{
        color:"primary",
        title: "Pending Discussion"
    },
    "approved":{
        color:"success",
        title: "Approved"
    },
    "cancelled":{
        color:"danger",
        title: "Cancelled"
    },
    "denied":{
        color:"danger",
        title: "Denied"
    },
};

export const HOA_ROLES_DATA = {
    "hoa":{
        color:"danger",
        title: "Super Admin"
    },
    "admin_staff":{
        color:"success",
        title: "Admin/Staff"
    },
    "review_board_member":{
        color:"primary",
        title: "Review Board Member"
    },
    "board_of_directors":{
        color:"info",
        title: "Board of Directors"
    }
};

export const ROLE_OPTIONS: any = [
    { value: 'admin_staff', label: 'Admin/Staff' },
    { value: 'review_board_member', label: 'Review Board Member' },
    { value: 'board_of_directors', label: 'Board of Directors' }
];

export const REQUEST_TYPE_DATA = {
    "request": "Architecture Review",
    "maintenance": "Maintenance",
    "feedback": "Feedback"
}

export const MODULE_ACCESS_LIST = {
    "administrator": [
        "requests",
        "member",
        "hoa",
        "settings",
    ],
    "hoa":[
        "requests",
        "member",
        "role",
        "property",
        "guideline",
        "acknowledgement"
    ],
    "admin_staff": [
        "requests",
        "member",
        "property",
        "guideline",
        "acknowledgement"
    ],
    "review_board_member": [
        "requests",
        "guideline",
    ],
    "board_of_directors": [
        "requests",
        "member",
        "property",
        "guideline",
        "acknowledgement"
    ]
};

export const getAccessibleModules = (user) => {
    if(user.role == 'administrator' || user.role == 'hoa'){
        return MODULE_ACCESS_LIST[user.role];
    }
    const allModules = user.assignRole.flatMap((r) => MODULE_ACCESS_LIST[r] || []); 
    return [...new Set(allModules)];
};