import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import { IPropertyUpload } from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PROPERTY_URL = `${API_URL}/admin/properties/import`

const fileUpload = async (property: IPropertyUpload): Promise<IPropertyUpload | undefined> => {
    try {
        const response = await axios.post(PROPERTY_URL, property);
        
        return response.data;
    } catch (error) {
        console.error('Error in createProperty:', error);
        throw error;
    }
};

export { fileUpload }
