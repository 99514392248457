import { ID, Response } from '../../../../_metronic/helpers'
export type Hoa = {
    id?: ID
    first_name?: string
    last_name?: string
    role?: string

}

export type HoaQueryResponse = Response<Array<Hoa>>

export const initialUser: Hoa = {
    first_name: '',
    last_name: '',
    role: '',

}


// response
export interface IHoaResponse {
    status: boolean;
    message: string;
    data: any;
    error: any;
}

export type IHoaCreate = {
    first_name: string
    last_name: string
    official_name: string
    primary_address: string
    email: string,
    password: string,
    //web_address: string
    // self_managed: boolean
    /* management_company: string;
    management_company_website: string;
    documents_bucket: string,
    plan_tier: string, */
    role: string,
    //tncs: string
}
export const hoaInitValues: IHoaCreate = {
    first_name: "",
    last_name: "",
    official_name:"",
    primary_address: "",
    email: "",
    password: "",
    //web_address: "",
    // self_managed: true,
    //management_company: "",
    //management_company_website: "",
    //documents_bucket: "",
    //plan_tier: "",
    role: "hoa",
    //tncs: ""
}
export type IHoaUpdate = {
    id: string,
    first_name: string,
    last_name: string,
    official_name: string
    primary_address: string,
    email: string,
    password: string,
    //web_address: string
    // self_managed: boolean,
    //management_company: string,
    //management_company_website: string,
    /* documents_bucket?: string,
    plan_tier?: string, */
    role?: string,
    //tncs?: string
}
export const hoaUpdateInitValues: IHoaUpdate = {
    id: "",
    first_name: "",
    last_name: "",
    official_name: "",
    primary_address: "",
    email: "",
    password: "",
    //web_address: "",
    // self_managed: true,
    //management_company: "",
    //management_company_website: "",
    //documents_bucket: "",
    //plan_tier: "",
    role: "hoa",
    //tncs: ""
}

