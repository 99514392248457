import { KTCardBody } from "../../../../_metronic/helpers"
import TableSkeleton from "../../../common/Skeleton/TableSkeleton"
import dateFormat from "dateformat"
function HoaGuideline({ loading, hoaDetails }) {
    return (
        <div className='card mb-5 mb-xl-10' id='kt_hoa_details_view'>
            <div className='card-header cursor-pointer'>
                <div className='card-title m-0 d-flex justify-content-between'>
                    <h3 className='fw-bolder m-0'>Guidelines</h3>
                </div>
            </div>
            <KTCardBody className='py-4'>
                <div className='table-responsive'>
                    <table
                        id='kt_table_guidelines'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                <th className="text-start col-1">No</th>
                                <th className="text-start col-9">Request item</th>
                                <th className="text-start col-2">Created At</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {loading ? (
                                <TableSkeleton rows={10} columns={3} />
                            ) : (
                                <>
                                    {hoaDetails.guidelines && hoaDetails.guidelines.length > 0 ? (

                                        <>
                                            {hoaDetails.guidelines.map((guide, index) => (
                                                <tr key={guide.id}>
                                                    <td className="text-start">{index + 1}</td>
                                                    <td className="text-start">{guide.request_item}</td>
                                                    <td className="text-start fs-7">{dateFormat(new Date(guide.created_at), "dS mmm, yyyy h:MM TT")}</td>
                                                </tr>
                                            ))}

                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <div className='d-flex fw-bold text-center fs-6 mt-5 w-100 align-content-center justify-content-center'>
                                                    No records have been added yet.
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>

                    </table>
                </div>
            </KTCardBody>
        </div>
    )
}

export default HoaGuideline