import clsx from "clsx";
import { FormikHelpers, useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup'
import { IGuidelineCreate, GuidelineInitValues as initialValues } from "./core/_models";
import { createGuideline } from "./core/_requests";
import { toast } from "react-toastify";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useThemeMode } from "../../../_metronic/partials";

const guidelineCreateSchema = Yup.object().shape({
    request_item: Yup.string().required('Request item is required'),
    snippet: Yup.string().required('Snippet is required'),
})
const GuidelineCreate: FC = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();
    const { mode } = useThemeMode();
    const { handleSubmit, touched, errors, getFieldProps, isSubmitting, isValid, setFieldValue, setFieldTouched, values } = useFormik<IGuidelineCreate>({
        initialValues: initialValues,
        validationSchema: guidelineCreateSchema,
        onSubmit: async (values: IGuidelineCreate, { setStatus, setSubmitting }: FormikHelpers<IGuidelineCreate>) => {
            setLoading(true)
            setError(null)
            try {
                values.snippet = `<div style="font-family: ${process.env.REACT_APP_CONTENT_FONT_FAMILY}; font-size: ${process.env.REACT_APP_CONTENT_FONT_SIZE};">${values.snippet}</div>`;
                const response: any = await createGuideline(values);
                const id = response.data.id;
                if (response.status) {
                    setLoading(false)
                    setSubmitting(false);
                    toast.success('Guideline created successfully.')
                    navigate(`/guidelines`);
                } 
                // else {
                //     toast.error(response.error);
                //     setSubmitting(false)
                //     setLoading(false)
                // }
            } catch (error) {
                // toast.error('Failed to create guideline. Please try again.')
                // setStatus('Failed to create guideline')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })
    return (
        <>
            {mode === 'dark' && (
                <style>{`
                    .ck.ck-editor__main>.ck-editor__editable{
                        background-color: #1C1C1C !important; 
                    }
                    `}
                </style>)
            }
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_guideline_create'
                    aria-expanded='true'
                    aria-controls='kt_guideline_create'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Create Guideline</h3>
                    </div>
                    <div className='card-title m-0'>
                        
                    </div>
                </div>
                <div id='kt_guideline_create'>

                    <form onSubmit={handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                            <div className="row mb-6">
                                <div className="col-lg-8 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Request Item</label>
                                    <input
                                        type='text'
                                        placeholder='Request Item'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.request_item && errors.request_item },
                                            {
                                                'is-valid': touched.request_item && !errors.request_item,
                                            }
                                        )}
                                        {...getFieldProps("request_item")}
                                    />
                                    {touched.request_item && errors.request_item && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.request_item}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-8 fv-row mt-5">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Snippet</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={values.snippet}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setFieldValue('snippet', data);
                                        }}
                                        onBlur={() => setFieldTouched('snippet')}
                                    />
                                    {touched.snippet && errors.snippet && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.snippet}</div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <Link to={"/guidelines"} className='btn btn-secondary me-3'>Cancel</Link>
                            <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                                {!loading && 'Create Guideline'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export { GuidelineCreate }