import {FC} from 'react'
import { Board } from '../../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  board: Board
}

const BoardInfoCell: FC<Props> = ({ board }) => (
  <div className='d-flex align-items-center'>
    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
      <a href="#"><div className="symbol-label fs-3 bg-light-danger text-danger">{board.member.official_name?.substring(0, 1).toUpperCase()}</div></a>
    </div>
    <div className='d-flex flex-column'>
      {board.role.includes('hoa') ? (
        <><a> {board.member.official_name}</a><span>{board.member.email}</span></>
      ):(<><Link to={`/members/${board.member.id}/edit`} className='text-gray-800 text-hover-primary mb-1'>
        {board.member.official_name}
      </Link><span>{board.member.email}</span></>)
      }
    </div>
  </div>
)

export { BoardInfoCell }
