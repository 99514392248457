
const generatePaginationLinks = (page: number, totalPages: number, pagesToShow: number = 1): any[] => {
    const links: any[] = [];

    links.push({
        label: 'Previous',
        page: page - 1,
        key: 'previous',
        disabled: page === 1,
    });

    const startPage = Math.max(page - pagesToShow, 2);
    const endPage = Math.min(page + pagesToShow, totalPages - 1);

    links.push({
        label: '1',
        page: 1,
        key: 'page-1',
    });

    if (page > pagesToShow + 2) {
        links.push({
            label: '...',
            key: 'ellipsis-prev',
        });
    }

    for (let i = startPage; i <= endPage; i++) {
        links.push({
            label: `${i}`,
            page: i,
            key: `page-${i}`,
        });
    }

    if (page < totalPages - pagesToShow - 1) {
        links.push({
            label: '...',
            key: 'ellipsis-next',
        });
    }

    if (totalPages > 1) {
        links.push({
            label: `${totalPages}`,
            page: totalPages,
            key: `page-${totalPages}`,
        });
    }

    links.push({
        label: 'Next',
        page: page + 1,
        key: 'next',
        disabled: page === totalPages,
    });

    return links;
};
export default generatePaginationLinks