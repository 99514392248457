import { Response } from "../../../../_metronic/helpers";

// response
export interface IResponse {
    status: boolean;
    message: string;
    data: any;
    error: any;
}
export type Acknowledgement = {
    title?: string
}

export type AcknowledgementQueryResponse = Response<Array<Acknowledgement>>

// create Acknowledgement
export type IAcknowledgementCreate = {
    id?: number
    title: string
}
export const AckInitValues: IAcknowledgementCreate = {
    title: "",
}

// update Acknowledgement
export type IAcknowledgementUpdate = {
    id?: number
    title: string
}
export const AckUpdateInitValues: IAcknowledgementUpdate = {
    title: "",
}