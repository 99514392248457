// @ts-nocheck
import { Column } from 'react-table'
import { GuidelineInfoCell } from './GuidelineInfoCell'
import { GuidelineStatusCell } from './GuidelineStatusCell'
import { GuidelineActionsCell } from './GuidelineActionsCell'
import { GuidelineSelectionCell } from './GuidelineSelectionCell'
import { GuidelineCustomHeader } from './GuidelineCustomHeader'
import { GuidelineSelectionHeader } from './GuidelineSelectionHeader'
import { Guideline } from '../../core/_models'
import dateFormat from "dateformat";

// const truncateString = (str, num) => {
//   return str && str.length > num ? str.slice(0, num) + '...' : str;
// }
const GuidelineColumns: ReadonlyArray<Column<Guideline>> = [
  {
    Header: (props) => <GuidelineCustomHeader tableProps={props} title='No.' className='min-w-100px' />,
    id: 'no'
  },
  {
    Header: (props) => <GuidelineCustomHeader tableProps={props} title='Request item' className='min-w-150px' />,
    id: 'request_item',
    accessor: 'request_item',
    Cell:({...props})=>(<GuidelineInfoCell guideline={props.data[props.row.index]}/>)
  },
  // {
  //   Header: (props) => <GuidelineCustomHeader tableProps={props} title='Snippet' className='min-w-125px' />,
  //   id: 'snippet',
  //   Cell: ({...props}) => (<div dangerouslySetInnerHTML={{ __html: truncateString(props.data[props.row.index].snippet, 80) }} />)
  // },
  {
    Header: (props) => (
      <GuidelineCustomHeader tableProps={props} title='Created at' className='min-w-125px' />
    ),
    id: 'created_at',
    Cell: ({ ...props }) => (<div>{dateFormat(new Date(props.data[props.row.index].created_at), "dS mmm, yyyy h:MM TT")}</div>)

  },
  {
    Header: (props) => (
      <GuidelineCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <GuidelineActionsCell id={props.data[props.row.index].id} />,
  },
]

export { GuidelineColumns }
