import axios, { AxiosResponse } from "axios";
import { IResponse, IGuidelineCreate, IGuidelineUpdate } from "./_models";
import { GuidelineQueryResponse } from "../../guideline/core/_models";
import { ID } from "../../../../_metronic/helpers";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL
const GUIDELINE_URL = `${API_URL}/admin/guidelines`
const REQUEST_GUIDELINE_URL = `${API_URL}/request/guidelines`
const ACKNOWLEDGEMENT_URL = `${API_URL}/admin/acknowledgements`

// Guideline Request
const getGuidelines = (query: string): Promise<GuidelineQueryResponse> => {
    return axios
        .get(`${GUIDELINE_URL}?${query}`)
        .then((d: AxiosResponse<GuidelineQueryResponse>) => d.data)
}
const getSingleGuidelines = (id: number): Promise<GuidelineQueryResponse> => {
    return axios
        .get(`${REQUEST_GUIDELINE_URL}/${id}`)
        .then((d: AxiosResponse<GuidelineQueryResponse>) => d.data)
}
async function createGuideline(payload: IGuidelineCreate): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.post(GUIDELINE_URL, payload);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to create guideline: " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function getGuideline(id: string | undefined): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.get(`${GUIDELINE_URL}/${id}`);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to get guideline: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Access denied or Guideline not found.');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function updateGuideline(payload: IGuidelineUpdate): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.patch(`${GUIDELINE_URL}/${payload.id}`, payload);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to update guideline: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Access denied or Guideline not found.');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function deleteGuideline(id: ID): Promise<IResponse | null> {
    try {
        const response: AxiosResponse<IResponse> = await axios.delete(`${GUIDELINE_URL}/${id}`);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to delete the guideline: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
const getAllGuidelines = (): Promise<GuidelineQueryResponse> => {
    return axios
        .get(`${GUIDELINE_URL}/all`)
        .then((d: AxiosResponse<GuidelineQueryResponse>) => d.data)
}
export { getGuidelines, createGuideline, getGuideline, updateGuideline, deleteGuideline, getSingleGuidelines,getAllGuidelines }