import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import { PropertyListHeader } from './components/PropertyListHeader'
import { PropertyTable } from './table/PropertyTable'
import {KTCard} from '../../../../_metronic/helpers'

const PropertyList = () => {
  return (
    <>
      <KTCard>
        <PropertyListHeader />
        <PropertyTable />
      </KTCard>
    </>
  )
}

const PropertyListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <PropertyList />
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { PropertyListWrapper }
