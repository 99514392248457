import clsx from 'clsx';
import {FC} from 'react';
import {HOA_ROLES_DATA} from '../../../../../../global.d';

type Props = {
  staff?: any
}

const RoleCell: FC<Props> = ({staff}) => {
  return (
    <div>
        {staff.role.map((roleKey) => {
            const role = HOA_ROLES_DATA[roleKey];
            if (role) {
                return (
                    <div 
                        key={roleKey} 
                        className={`badge fw-bolder fs-7 m-1 badge-${role.color}`}
                    >
                        {role.title}
                    </div>
                );
            }
            return null;
        })}
    </div>
  ); 
}

export { RoleCell }
