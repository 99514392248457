import clsx from "clsx";
import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup'
import { IBoardUpdate, boardUpdateInitValues as initialValues } from "./core/_models";
import { getBoard, updateBoard } from "./core/_requests";
import { toast } from "react-toastify";
import InputSkeleton from "../../common/Skeleton/InputSkeleton";
import { ROLE_OPTIONS } from "../../../global.d";
import Select from 'react-select';

const boardCreateSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required').email('Please enter a valid email format'),
    password: Yup.string()
        .nullable()
        .test('password', 'Password must be at least 8 characters long', function (value) {
            if (value && value.length > 0) {
                return value.length >= 8;
            }
            return true;
        })
        .test('password-pattern', 'Password must match the required pattern', function (value) {
            if (value && value.length > 0) {
                return /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*/.test(value);
            }
            return true;
        }),
        role: Yup.array().min(1, 'Selection is required').nullable(),
});

const RoleAssign: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [btnloading, setBtnLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [selectedRole, setSelectedRole] = useState([]);

    const { handleSubmit, touched, errors, getFieldProps, isSubmitting, isValid, setValues, setFieldValue } = useFormik<IBoardUpdate>({
        initialValues: initialValues,
        validationSchema: boardCreateSchema,
        onSubmit: async (values: IBoardUpdate, { setStatus, setSubmitting }: FormikHelpers<IBoardUpdate>) => {
            setBtnLoading(true);
            setError(null);
            try {
                const roleArrData:any = values.role??[];
                const roleArr = roleArrData.map(item => item.value);
                values.role = roleArr;
                const response: any = await updateBoard(values);
                if (response.status === true) {
                    toast.success('Staff updated successfully.');
                    navigate('/staff');
                }
            } catch (error) {
                setBtnLoading(false);
            } 
        },
    });

    useEffect(() => {
        const fetchBoard = async () => {
            setLoading(true);
            try {
                const response: any = await getBoard(id);
                if (response.status) {
                    const selectedValues:any = ROLE_OPTIONS.filter(option => response.data.roles.includes(option.value));
                    setSelectedRole(selectedValues)
                    setValues({
                        id: response.data.id || "",
                        first_name: response.data.first_name || "",
                        last_name: response.data.last_name || "",
                        email: response.data.email || "",
                        password: response.data.password || "",
                        role: selectedValues || [],
                    });
                }
            } catch (error) {
                setError('Failed to fetch Board details');
            } finally {
                setLoading(false);
            }
        };
        fetchBoard();
    }, [id]);

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_board_create'
                aria-expanded='true'
                aria-controls='kt_board_create'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Edit Board</h3>
                </div>
                <div className='card-title m-0'>
                    <Link to={"/staff"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                </div>
            </div>
            <div id='kt_board_create'>
                <form onSubmit={handleSubmit} className='form'>
                    <div className='card-body border-top p-9'>
                        {loading ? <InputSkeleton width={530} colsWidth={6} /> :
                            <>
                                <div className="row mb-6">
                                    <div className="col-lg-6 fv-row">
                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>First Name</label>
                                        <input
                                            type='text'
                                            placeholder='Name'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': touched.first_name && errors.first_name },
                                                { 'is-valid': touched.first_name && !errors.first_name }
                                            )}
                                            {...getFieldProps("first_name")}
                                        />
                                        {touched.first_name && errors.first_name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.first_name}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 fv-row">
                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Last Name</label>
                                        <input
                                            type='text'
                                            placeholder='Last Name'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': touched.last_name && errors.last_name },
                                                { 'is-valid': touched.last_name && !errors.last_name }
                                            )}
                                            {...getFieldProps("last_name")}
                                        />
                                        {touched.last_name && errors.last_name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.first_name}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-6 fv-row">
                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Email</label>
                                        <input
                                            type='email'
                                            placeholder='Email'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': touched.email && errors.email },
                                                { 'is-valid': touched.email && !errors.email }
                                            )}
                                            {...getFieldProps("email")}
                                        />
                                        {touched.email && errors.email && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.email}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mb-6">
                                    <div className="col-lg-6 fv-row">
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Password</label>
                                        <input
                                            type='password'
                                            placeholder='Password'
                                            className={clsx(
                                                'form-control form-control-lg',
                                                { 'is-invalid': touched.password && errors.password },
                                                { 'is-valid': touched.password && !errors.password }
                                            )}
                                            {...getFieldProps("password")}
                                        />
                                        {touched.password && errors.password && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.password}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-lg-6 fv-row">
                                        <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Assign Role</label>
                                        <Select 
                                            name="role"
                                            className='react-select-styled react-select-solid react-select-lg  min-w-250px'
                                            classNamePrefix='react-select' 
                                            options={ROLE_OPTIONS} 
                                            placeholder='Select a Status...' 
                                            isMulti 
                                            onChange={(value:any) => {
                                                setSelectedRole(value)
                                                setFieldValue('role', value)
                                            }}
                                            value={selectedRole}
                                        />
                                        {touched.role && errors.role && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.role}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                            {!btnloading && 'Update Board'}
                            {btnloading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export { RoleAssign };
