import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from '../table/columns/CustomHeaderColumn'
import { CustomRow } from '../table/columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { PropertyColumns } from './columns/_columns'
import { Property } from '../../core/_models'
import { ListLoading } from '../../../../common/ListLoading'
import { KTCardBody } from '../../../../../_metronic/helpers'
import TableSkeleton from '../../../../common/Skeleton/TableSkeleton'
import { PropertyListPagination } from '../components/PropertyListPagination'
const PropertyTable = () => {
  const property = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => property, [property])
  const columns = useMemo(() => PropertyColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_property'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Property>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {isLoading ? (
              <TableSkeleton rows={10} columns={6} />
            ) :
              rows.length > 0 ? (
                rows.map((row: Row<Property>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )
            }

          </tbody>
        </table>
      </div>
      <PropertyListPagination />
      {/* {isLoading && <ListLoading />} */}
    </KTCardBody>
  )
}

export { PropertyTable }
