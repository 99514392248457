import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import { HoaListHeader } from './components/HoaListHeader'
import { HoaTable } from './table/HoaTable'
import {KTCard} from '../../../../_metronic/helpers'

const HoaList = () => {
  return (
    <>
      <KTCard>
        <HoaListHeader />
        <HoaTable />
      </KTCard>
    </>
  )
}

const HoaListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <HoaList />
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { HoaListWrapper }
