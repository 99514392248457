import clsx from "clsx";
import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup'
import { IHoaUpdate, hoaUpdateInitValues as initialValues } from "./core/_models";
import { getHoa, updateHoa } from "./core/_requests";
import { toast } from "react-toastify";
import InputSkeleton from "../../common/Skeleton/InputSkeleton";
const hoaCreateSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    official_name: Yup.string().required('Official name is required'),
    //business_address: Yup.string().required('Business address is required'),
    email: Yup.string().required('Email is required').email('Please enter valid email formate'),
    password: Yup.string()
        .nullable()
        .test('password', 'Password must be at least 8 characters long', function (value) {
            if (value && value.length > 0) {
                return value.length >= 8;
            }
            return true;
        })
        .test('password-pattern', 'Password must match the required pattern', function (value) {
            if (value && value.length > 0) {
                return /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*/.test(value);
            }
            return true;
        }),
    //web_address: Yup.string().required('Web address is required'),
    //management_company: Yup.string().required('Management company is required'),
    //management_company_website: Yup.string().required('Management company website is required'),
})
const HoaEdit: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false)
    const [btnloading, setBtnLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();

    const { handleSubmit, touched, errors, getFieldProps, isSubmitting, isValid, setValues } = useFormik<IHoaUpdate>({
        initialValues: initialValues,
        validationSchema: hoaCreateSchema,
        onSubmit: async (values: IHoaUpdate, { setStatus, setSubmitting }: FormikHelpers<IHoaUpdate>) => {
            setBtnLoading(true)
            setError(null)
            try {
                const response: any = await updateHoa(values);

                if (response.status == true) {
                    toast.success('HOA updated successfully.');
                    navigate('/hoa');
                }
            } catch (error) {
                setBtnLoading(false);
                // console.error('Failed to update Hoa:', error);
                // setError('Failed to update Hoa. Please try again.');
            }
            //  finally {
            //     setBtnLoading(false);
            // }
        },
    })

    useEffect(() => {
        const fetchHoaUser = async () => {
            setLoading(true)
            try {
                const response: any = await getHoa(id);

                if (response) {
                    setValues({
                        id: response.data.id || "",
                        first_name: response.data.first_name || "",
                        last_name: response.data.last_name || "",
                        official_name: response.data.official_name || "",
                        primary_address: response.data.primary_address || "",
                        email: response.data.email || "",
                        password: response.data.password || "",
                        //web_address: response.data.web_address || "",
                        // self_managed: response.data.self_managed || "",
                        //management_company: response.data.management_company || "",
                        //management_company_website: response.data.management_company_website || "",
                        //documents_bucket: "",
                        //plan_tier: "",
                        //tncs: "",
                        role: response.data.role || ""
                    })

                }
            } catch (error) {
                setLoading(false)
            } finally {
                setLoading(false)
            }
        };
        fetchHoaUser();
    }, [id]);
    return (

        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-target='#kt_hoa_edit'
                aria-expanded='true'
                aria-controls='kt_hoa_edit'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Edit HOA</h3>
                </div>
                <div className='card-title m-0'>
                    <Link to={"/hoa"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                </div>
            </div>
            <div id='kt_hoa_edit'>
                <form onSubmit={handleSubmit} className='form'>
                    {loading ? <InputSkeleton rows={4} columns={2} /> :
                        <div className='card-body border-top p-9'>
                            <div className="row mb-6">
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>First Name</label>
                                    <input
                                        type='text'
                                        placeholder='Official Name'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.first_name && errors.first_name },
                                            {
                                                'is-valid': touched.first_name && !errors.first_name,
                                            }
                                        )}
                                        {...getFieldProps("first_name")}
                                    />
                                    {touched.first_name && errors.first_name && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.first_name}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Last Name</label>
                                    <input
                                        type='text'
                                        placeholder='Short Name'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.last_name && errors.last_name },
                                            {
                                                'is-valid': touched.last_name && !errors.last_name,
                                            }
                                        )}
                                        {...getFieldProps("last_name")}
                                    />
                                    {touched.last_name && errors.last_name && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.last_name}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-6">
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Official Name</label>
                                    <input
                                        type='text'
                                        placeholder='Last Name'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.official_name && errors.official_name },
                                            {
                                                'is-valid': touched.official_name && !errors.official_name,
                                            }
                                        )}
                                        {...getFieldProps("official_name")}
                                    />
                                    {touched.official_name && errors.official_name && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.official_name}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Email</label>
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.email && errors.email },
                                            {
                                                'is-valid': touched.email && !errors.email,
                                            }
                                        )}
                                        {...getFieldProps("email")}
                                    />
                                    {touched.email && errors.email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.email}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-6">
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Password</label>
                                    <input
                                        type='password'
                                        placeholder='Password'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.password && errors.password },
                                            {
                                                'is-valid': touched.password && !errors.password,
                                            }
                                        )}
                                        {...getFieldProps("password")}
                                    />
                                    {touched.password && errors.password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.password}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Business Address</label>
                                    <input
                                        type='text'
                                        placeholder='Business Address'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.primary_address && errors.primary_address },
                                            {
                                                'is-valid': touched.primary_address && !errors.primary_address,
                                            }
                                        )}
                                        {...getFieldProps("primary_address")}
                                    />
                                    {touched.primary_address && errors.primary_address && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.primary_address}</div>
                                        </div>
                                    )}
                                </div>
                                {/* <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>Web Address</label>
                                    <input
                                        type='text'
                                        placeholder='Web Address'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': touched.web_address && errors.web_address },
                                            {
                                                'is-valid': touched.web_address && !errors.web_address,
                                            }
                                        )}
                                        {...getFieldProps("web_address")}
                                    />
                                    {touched.web_address && errors.web_address && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{errors.web_address}</div>
                                        </div>
                                    )}
                                </div> */}
                            </div>
                            {/* <div className="row mb-6">
                                        <div className="col-lg-6 fv-row">
                                            <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Management Company</label>
                                            <input
                                                type='text'
                                                placeholder='Management Company'
                                                className={clsx(
                                                    'form-control form-control-lg',
                                                    { 'is-invalid': touched.management_company && errors.management_company },
                                                    {
                                                        'is-valid': touched.management_company && !errors.management_company,
                                                    }
                                                )}
                                                {...getFieldProps("management_company")}
                                            />
                                            {touched.management_company && errors.management_company && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{errors.management_company}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-lg-6 fv-row">
                                            <label className='col-lg-6 col-form-label fw-bold fs-6 required'>Management Company Website</label>
                                            <input
                                                type='text'
                                                placeholder='Management Company Website'
                                                className={clsx(
                                                    'form-control form-control-lg',
                                                    { 'is-invalid': touched.management_company_website && errors.management_company_website },
                                                    {
                                                        'is-valid': touched.management_company_website && !errors.management_company_website,
                                                    }
                                                )}
                                                {...getFieldProps("management_company_website")}
                                            />
                                            {touched.management_company_website && errors.management_company_website && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{errors.management_company_website}</div>
                                                </div>
                                            )}
                                        </div>

                                    </div> */}
                            {/* <div className="row mb-6">
                                            <div className="col-lg-6 fv-row">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Is Self-Managed</label>
                                                <div className="form-check form-check-solid form-switch fv-row">
                                                    <input
                                                        className="form-check-input w-60px h-40px"
                                                        type="checkbox"
                                                        checked={getFieldProps("self_managed").value}
                                                        {...getFieldProps("self_managed")}

                                                    />
                                                    <label className="form-check-label"></label>
                                                </div>
                                            </div>
                                    </div> */}
                        </div>
                    }
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                            {!btnloading && 'Update HOA'}
                            {btnloading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
export { HoaEdit }