import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

interface TableSkeletonProps {
    rows: number
    columns: number
    height?: number
    baseColor?: string
    highlightColor?: string
    duration?: number
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({
    rows,
    columns,
    height = 20,
    baseColor = "#ebebeb",
    highlightColor = "#f5f5f5",
    duration = 5,
}) => {
    return (
        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor} duration={duration}>
            {Array(rows).fill(null).map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {Array(columns).fill(null).map((_, colIndex) => (
                        <td key={colIndex}>
                            <Skeleton height={height} />
                        </td>
                    ))}
                </tr>
            ))}
        </SkeletonTheme>
    )
}

export default TableSkeleton
