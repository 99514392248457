import axios, { AxiosResponse } from "axios";
import { IMemberCreate, IMemberResponse, IMemberUpdate } from "./_models";
import { ID } from "../../../../_metronic/helpers";
import { User, UsersQueryResponse } from './_models'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL
const MEMBER_URL = `${API_URL}/admin/member`
const REQUEST_URL = `${API_URL}/admin/request`
const PROPERTY_URL = `${API_URL}/admin/properties`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
    return axios
        .get(`${MEMBER_URL}?${query}`)
        .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

// Create Member
async function createMember(payload: IMemberCreate): Promise<IMemberResponse | null> {
    try {
        const response: AxiosResponse<IMemberResponse> = await axios.post(MEMBER_URL, payload);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to created member: " + response.data.error);
            return null;
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}

// Get Member
async function getMember(id: string): Promise<IMemberResponse | null> {
    try {
        const response: AxiosResponse<IMemberResponse> = await axios.get(`${MEMBER_URL}/${id}`);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to get member : " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Access denied or member not found');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}

// Update Member
async function updateMember(payload: IMemberUpdate): Promise<IMemberResponse | null> {
    try {
        const response: AxiosResponse<IMemberResponse> = await axios.patch(`${MEMBER_URL}/${payload.id}`, payload);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to update member: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Access denied or member not found.');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}

// Delete Member
async function deleteMember(id: ID): Promise<IMemberResponse | null> {
    try {
        const response: AxiosResponse<IMemberResponse> = await axios.delete(`${MEMBER_URL}/${id}`);
        if (response && response.data.status === true) {
            return response.data;
        } else {
            toast.error('Failed to delete the member: ' + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}

async function searchHoa(keyword: any): Promise<IMemberResponse | null> {
    try {
        const response: AxiosResponse<IMemberResponse> = await axios.post(`${REQUEST_URL}/search-hoa`, { keyword });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            toast.error('Failed to fetch hoa. Please try again');
        }
        return null;
    }
}
async function sendInvitation(id: string): Promise<IMemberResponse | null> {
    try {
        const response: AxiosResponse<IMemberResponse> = await axios.get(`${MEMBER_URL}/resend-mail/${id}`);
        if (response && response.data.status == true) {
            return response.data;
        } else {
            toast.error("Failed to resend invitation: " + response.data.error);
            return null;
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                toast.error('Server Error. Please try again');
            } else if (error.request) {
                toast.error('No response from server. Please check your network.');
            } else {
                toast.error('Request setup error.');
            }
        } else {
            toast.error('An unexpected error occurred.');
        }
        return null;
    }
}
async function searchAddress(keyword: any, isAll: boolean, property_id?: any): Promise<IMemberResponse | null> {
    try {
        const response: AxiosResponse<IMemberResponse> = await axios.post(`${PROPERTY_URL}/search-property`, { keyword, isAll, property_id });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            toast.error('Failed to fetch hoa. Please try again');
        }
        return null;
    }
}
export { getUsers, createMember, sendInvitation, searchHoa, getMember, updateMember, deleteMember, searchAddress };
