import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import { BoardListHeader } from './components/BoardListHeader'
import { BoardTable } from './table/BoardTable'
import {KTCard} from '../../../../_metronic/helpers'

const BoardList = () => {
  return (
    <>
      <KTCard>
        <BoardListHeader />
        <BoardTable />
      </KTCard>
    </>
  )
}

const BoardListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <BoardList />
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { BoardListWrapper }
