import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import { FormikHelpers, useFormik } from 'formik'
import {KTIcon} from "../../../../../_metronic/helpers"
import Select from 'react-select';
import { ROLE_OPTIONS } from '../../../../../global.d';
import { updateBoardRole } from '../../core/_requests'
import { searchBoards } from '../../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import { toast } from 'react-toastify';
import { searchMember } from '../../../property/core/_requests';

type IRoleCreate = {
  member: { value: string; label: string }; // Ensure type matches Select options
  role: { value: string; label: string }[];
};

const editUserSchema = Yup.object().shape({
  member: Yup.object()
    .nullable()
    .test('isValidMember', 'A member must be selected', (value:any) => value && value.value),
  role: Yup.array()
    .min(1, 'At least one role is required')
    .nullable(),
});

const RoleEditModal = ({itemIdForUpdate, setItemIdForUpdate}:any) => {

  const [options, setOptions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const {refetch} = useQueryResponse();
  const [selectedRole, setSelectedRole] = useState([]);

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        if (searchKeyword) {
          const response:any = await searchMember(searchKeyword);
          console.log(response,'search member');
          
          if (response) {
            setOptions(response.data.map((item) => ({
              value: item.id,
              label: (
                <div>
                  <strong>{item.official_name}</strong>
                  <br />
                  <small>{item.email}</small>
                </div>
              ),

              // label:`${item.official_name}${item.email}`,
              // label: item.official_name + '  ' +item.email,
              role: item.role
            })))
          }
        }
      } catch (error) {

      }
    }
    fetchStaff()
  }, [searchKeyword]);
  
  const { handleSubmit, touched, errors, values, setFieldValue, isSubmitting, isValid }:any =
    useFormik<IRoleCreate>({
      initialValues: {
        member: { value: "", label: "" },
        role: [],
      },
      validationSchema: editUserSchema,
      onSubmit: async (values: IRoleCreate, { setSubmitting }: FormikHelpers<IRoleCreate>) => {
        setSubmitting(true);
        try {
          const roleArrData:any = values.role??[];
          const roleArr = roleArrData.map(item => item.value);
          const formData = {
            role: roleArr,
            member_id: values.member.value
          };
          const response: any = await updateBoardRole(formData);
        } catch (ex) {
          console.error(ex);
        } finally {
          setSubmitting(false);
          setItemIdForUpdate(false);
          refetch();
          toast.success('Role assigned successfully.')
        }
      },
    })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Assign Member Role</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setItemIdForUpdate(false)} 
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body mx-5 mx-xl-15 my-7'>
              <form id='kt_modal_add_user_form' className='form' onSubmit={handleSubmit}>
                {/* begin::Scroll */}
                <div
                  className='d-flex flex-column me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  

                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2'>Member</label>
                    <Select
                      name="member"
                      options={options}
                      onChange={(value:any) => {
                        setFieldValue('member', value);
                        //const selectedValues:any = ROLE_OPTIONS.filter(option => value.role.includes(option.value));
                        //setSelectedRole(selectedValues);
                      }}
                      onInputChange={(keyword) => (setSearchKeyword(keyword))}
                      isSearchable
                      placeholder="Search Members..."
                      //value={(Object.keys(selectedHoa).length > 0)? selectedHoa:""}
                      className=""
                    />
                    {touched.member && errors.member && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.member}</div>
                      </div>
                    )}
                    {/* end::Input */}
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='fv-row mb-7'>
                    {/* begin::Label */}
                    <label className='required fw-bold fs-6 mb-2'>Role</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <Select 
                      name="role"
                      className='react-select-styled react-select-solid react-select-lg  min-w-250px'
                      classNamePrefix='react-select' 
                      options={ROLE_OPTIONS} 
                      placeholder='Select a Role...' 
                      isMulti 
                      onChange={(value:any) => {
                          setFieldValue('role', value);
                          setSelectedRole(value);
                      }}
                      value={selectedRole}
                    />
                    {touched.role && errors.role && (
                      <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.role}</div>
                      </div>
                    )}
                  </div>
                  {/* end::Input group */}
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                  <button
                    type='reset'
                    onClick={() => setItemIdForUpdate(false)}
                    className='btn btn-light me-3'
                    data-kt-users-modal-action='cancel'
                    disabled={isSubmitting}
                  >Cancel</button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    data-kt-users-modal-action='submit'
                    disabled={isSubmitting || !isValid || !touched}
                  >
                    <span className='indicator-label'>Submit</span>
                    {(isSubmitting) && (
                      <span className='indicator-progress'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Actions */}
              </form>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {RoleEditModal}
