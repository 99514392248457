import clsx from "clsx";
import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IPropertyCreate, propertyInitValues as initialValues } from "./core/_models";
import { toast } from "react-toastify";
import { searchMember } from "./core/_requests";
import { createProperty } from "./core/_requests";
import Select from 'react-select';
import * as Yup from 'yup'

const propertyCreateSchema = Yup.object().shape({
    /* member: Yup.object()
    .nullable()
    .test('isValidMember', 'A member must be selected', (value:any) => value && value.value), */
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('Zip is required'),
    plat: Yup.string().required('Plat is required')
})
interface OptionType {
    value: string;
    label: string;
}

const PropertyCreate: FC = () => {

    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<OptionType[]>([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMember = async () => {
            try {
                if (searchKeyword) {
                    const response = await searchMember(searchKeyword);
                    if (response) {
                        setOptions(response.data.map((item) => ({
                            value: item.id,
                            label: item.first_name + ' ' + item.last_name,
                        })))
                    }
                }
            } catch (error) {

            }
        }
        fetchMember()
    }, [searchKeyword]);

    const formik:any = useFormik<IPropertyCreate>({
        initialValues: { ...initialValues },
        validationSchema: propertyCreateSchema,
        onSubmit: async (values: IPropertyCreate, { setStatus, setSubmitting }: FormikHelpers<IPropertyCreate>) => {

            setLoading(true)
            try {
               /*  const selectedMember:any = values?.member;
                values.member_id = selectedMember.value??''; */
                const data: any = await createProperty(values);
                if (data.status) {
                    setLoading(false)
                    setSubmitting(false);
                    toast.success('Property created successfully.')
                    navigate('/property');
                } else {
                    toast.error(data.error);
                    setSubmitting(false)
                    setLoading(false)
                }
            } catch (error) {
                toast.error('Failed to create property. Please try again.')
                setStatus('Failed to create property')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_property_create'
                    aria-expanded='true'
                    aria-controls='kt_property_create'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Create Property</h3>
                    </div>
                    <div className='card-title m-0'>
                        <Link to={"/property"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                    </div>
                </div>
                <div id='kt_property_create' className='collapse show'>
                    <form onSubmit={formik.handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                            <div className="row mb-6">
                                {/* <div className="col-lg-6 fv-row">
                                    <label className="col-form-label required fw-semibold fs-6">Select Member</label>
                                    <Select
                                        name="member"
                                        options={options}
                                        onChange={(value:any) => {
                                            formik.setFieldValue('member', value);
                                          }}
                                        onInputChange={(keyword) => setSearchKeyword(keyword)}
                                        isSearchable
                                        placeholder="Search Members..."
                                        className=""
                                    />
                                    {formik.touched.member && formik.errors.member && (
                                        <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.member}</div>
                                        </div>
                                    )}
                                </div> */}
                                <div className="col-lg-12 fv-row">
                                    <label className="col-form-label required fw-semibold fs-6">Address</label>
                                    <textarea
                                        placeholder='Address'
                                        rows={5}
                                        cols={10}
                                        className={clsx(
                                            'form-control form-control-lg mb-3 mb-lg-0',
                                            { 'is-invalid': formik.touched.address && formik.errors.address },
                                            {
                                                'is-valid': formik.touched.address && !formik.errors.address,
                                            }
                                        )}
                                        {...formik.getFieldProps('address')}
                                    ></textarea>
                                    {formik.touched.address && formik.errors.address && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.address}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-6">
                                <div className="col-lg-6 fv-row">
                                    <label className="col-form-label required fw-semibold fs-6">City</label>
                                    <input
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.city && formik.errors.city },
                                            {
                                                'is-valid': formik.touched.city && !formik.errors.city,
                                            }
                                        )}
                                        placeholder='City'
                                        {...formik.getFieldProps('city')}
                                    />
                                    {formik.touched.city && formik.errors.city && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.city}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>State</label>
                                    <input
                                        type='text'
                                        placeholder='State'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.state && formik.errors.state },
                                            {
                                                'is-valid': formik.touched.state && !formik.errors.state,
                                            }
                                        )}
                                        {...formik.getFieldProps('state')}
                                    />
                                    {formik.touched.state && formik.errors.state && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.state}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-6">
                                <div className="col-lg-6 fv-row">
                                    <label className="col-form-label required fw-semibold fs-6">Zip</label>
                                    <input
                                        type='text'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.zip && formik.errors.zip },
                                            {
                                                'is-valid': formik.touched.zip && !formik.errors.zip,
                                            }
                                        )}
                                        placeholder='Zip'
                                        {...formik.getFieldProps('zip')}
                                    />
                                    {formik.touched.zip && formik.errors.zip && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.zip}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Plat</label>
                                    <input
                                        type='text'
                                        placeholder='Plat'
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.plat && formik.errors.plat },
                                            {
                                                'is-valid': formik.touched.plat && !formik.errors.plat,
                                            }
                                        )}
                                        {...formik.getFieldProps('plat')}
                                    />
                                    {formik.touched.plat && formik.errors.plat && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.plat}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || !formik.isValid}>
                                {!loading && 'Create Property'}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export { PropertyCreate }