import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/verify_token`
export const LOGIN_URL = `${API_URL}/admin/login`
export const REQUEST_PASSWORD_URL = `${API_URL}/admin/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/admin/reset-password`;
export const CHECK_SESSION_STATUS_URL =`${API_URL}/checkSession`
export const UPDATE_PROFILE_URL = `${API_URL}/admin/profile`;
export const GET_SETTINGS_URL = `${API_URL}/admin/setting`;
export const UPDATE_SETTINGS_URL = `${API_URL}/admin/setting`;
export const UPDATE_PASSWORD_URL = `${API_URL}/admin/password`;
export const FETCH_NOTIFICATION_DATA =`${API_URL}/admin/notifications`


// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function resetPassword(payload: object) {
  return axios.post(RESET_PASSWORD_URL,payload) 
}

export function updateProfile(payload: object) {
  return axios.post(UPDATE_PROFILE_URL,payload) 

}
export function getSettingsData() {
  return axios.get(GET_SETTINGS_URL,)

}
export function updateSettings(payload: object) {
  return axios.patch(UPDATE_SETTINGS_URL,payload) 

}

export function changePassword(payload: object) {
  return axios.post(UPDATE_PASSWORD_URL,payload) 

}

export function isSessionExpired() {
  return axios.get(`${CHECK_SESSION_STATUS_URL}`);
}

export function getNotificationData() {
  return axios.get(`${FETCH_NOTIFICATION_DATA}`);
}