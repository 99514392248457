// @ts-nocheck
import { Column } from 'react-table'
import { PropertyInfoCell } from './PropertyInfoCell'
import { PropertyMemberInfoCell } from './PropertyMemberInfoCell'
import { PropertyStatusCell } from './PropertyStatusCell'
import { PropertyActionsCell } from './PropertyActionsCell'
import { PropertySelectionCell } from './PropertySelectionCell'
import { PropertyCustomHeader } from './PropertyCustomHeader'
import { PropertySelectionHeader } from './PropertySelectionHeader'
import { Property } from '../../core/_models'
import dateFormat from "dateformat";

const PropertyColumns: ReadonlyArray<Column<Property>> = [
  {
    Header: (props) => <PropertyCustomHeader tableProps={props} title='No.' className='min-w-40px' />,
    id: 'no'
  },
  {
    Header: (props) => <PropertyCustomHeader tableProps={props} title='Address' className='min-w-150px' />,
    id: 'address',
    /* accessor: (row) => {
      return row? `${row.address || ""}` +
          (row.city ? `, ${row.city}` : "") +
          (row.plat ? `, ${row.plat}` : "") +
          (row.state ? `, ${row.state}` : "") +
          (row.zip ? ` ${row.zip}` : "")
        : '';
    }, */
    Cell: ({ ...props }) => <PropertyInfoCell property={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <PropertyCustomHeader tableProps={props} title='Member' className='min-w-100px' />
    ),
    id: 'member',
    Cell: ({ ...props }) => <PropertyMemberInfoCell property={props.data[props.row.index]} />,
  },
  { 
    Header: (props) => (
      <PropertyCustomHeader tableProps={props} title='Created at' className='min-w-125px' />
    ),
    id: 'created_at',
    Cell: ({ ...props }) => (<div>{dateFormat(new Date(props.data[props.row.index].created_at), "dS mmm, yyyy h:MM TT")}</div>)

  },
  {
    Header: (props) => (
      <PropertyCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <PropertyActionsCell id={props.data[props.row.index].id} />,
  },
]

export { PropertyColumns }
