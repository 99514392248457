import { ID, Response } from '../../../../_metronic/helpers'
export type Property = {
    first_name?: string
    address?: string
    city?: string
    state?: string
    zip: string
    plat?: string;
    member_id: string;
    member:object;
    id: string;
}

export type PropertyQueryResponse = Response<Array<Property>>

export const initialUser: Property = {
    first_name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    plat: '',
    member_id: "",
    id: '',
    member: { value: "", label: "" }
}


// response
export interface IPropertyResponse {
    success: boolean;
    message: string;
    data: any;
    error: any;
}

// Member create interface
export interface IPropertyCreate {
    first_name: string,
    last_name: string,
    email: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    plat: string,
    member: object,
    member_id: string
    
}
export const propertyInitValues: IPropertyCreate = {
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    plat: '',
    member_id:'',
    member: { value: "", label: "" }
}

// Member update interface
export interface IPropertyUpdate {
    id: string
    address: string,
    city: string,
    state: string,
    zip: string,
    plat: string,
    member_id: string
    member: object
    impactedProperties: {
        member_id: string;
        property_id: string;
    }[];
}
export const propertyUpdateInitValues: IPropertyUpdate = {
    id: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    plat: '',
    member_id: "",
    member: { value: "", label: "" },
    impactedProperties: [],
}


export interface IImpactedPropertyUpdate {
    id: string
    //member_id: string
    property_id: string[]
}

export const impactedPropertyUpdateInitValues: IImpactedPropertyUpdate = {
    id: '',
    //member_id: '',
    property_id:[]
}