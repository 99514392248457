import { useState, useEffect } from "react";
import * as Yup from 'yup'
import { useFormik } from "formik";
import clsx from "clsx";
import { useNavigate, useRoutes, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { toast } from "react-toastify";
import { resetPassword } from "../core/_requests";

const initialValues = {
  password: "",
  confirmpassword: "",
};

const setNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 characters.')
    .max(50, 'Maximum 50 characters.')
    .required('Password is required.'),
    confirmpassword: Yup.string()
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match.")
});

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("Enter New Password");
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();

 

  const formik = useFormik({
    initialValues,
    validationSchema: setNewPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting,setErrors }) => {
      setLoading(true);
      try {
        const payload = {
          password: values.password,
          confirm_password:values.confirmpassword,
          token: queryParameters.get("token"),
          email: queryParameters.get("email"),
        };
        
        const response  = await resetPassword(payload);
        if(response.data.status){
          toast.success(response.data.message);
          setTimeout(()=>{
            navigate('/');
          },1000)
        }
        else{
          toast.error(response.data.message);
        }
        setHasErrors(!response.data.status);
        setMessage(response.data.message);
        !response.data.status && response.data.error && setErrors(response.data.error);
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {hasErrors === true ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{message}</div>
        </div>
      ) : ''}
        {/* start::Form group */}
      <div className="fv-row mb-8" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            New Password
          </label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder="Password"
              autoComplete="off"
              disabled={formik.isSubmitting}
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">
          Confirm New Password
        </label>
        <input
          type="password"
          disabled={formik.isSubmitting}
          placeholder="Password confirmation"
          autoComplete="off"
          {...formik.getFieldProps("confirmpassword")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid":
                formik.touched.confirmpassword && formik.errors.confirmpassword,
            },
            {
              "is-valid":
                formik.touched.confirmpassword && !formik.errors.confirmpassword,
            }
          )}
        />
        {formik.touched.confirmpassword && formik.errors.confirmpassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirmpassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' 
          disabled={formik.isSubmitting || !formik.isValid}
          id='kt_password_reset_submit' className='btn btn-primary me-4'>
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: "block" }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>

      {/* end::Form group */}
    </form>
  );
}