import {FC} from 'react'
import { Guideline } from '../../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  guideline: Guideline
}

const GuidelineInfoCell: FC<Props> = ({ guideline }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <Link to={`/guidelines/${guideline.id}/edit`} className='text-gray-800 text-hover-primary mb-1'>
        {guideline.request_item}
      </Link>
    </div>
  </div>
)

export { GuidelineInfoCell }
