import { FormikHelpers, useFormik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup'
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getAcknowledgement, updateAcknowledgement } from "./core/_requests";
import { IAcknowledgementUpdate, AckInitValues as initialValues } from "./core/_models";
import InputSkeleton from "../../common/Skeleton/InputSkeleton";
import { useThemeMode } from "../../../_metronic/partials";
const acknowledgementEditSchema = Yup.object().shape({
    title: Yup.string().required('Title is required')
})

const AcknowledgementEdit: FC = () => {
    const editorRef = useRef<HTMLElement | null>(null);
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false)
    const [btnloading, setBtnLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();
    const { mode } = useThemeMode();

    const { handleSubmit, touched, errors, getFieldProps, isSubmitting, isValid, values, setFieldValue, setFieldTouched, setValues } = useFormik<IAcknowledgementUpdate>({
        initialValues: initialValues,
        validationSchema: acknowledgementEditSchema,
        onSubmit: async (values: IAcknowledgementUpdate, { setStatus, setSubmitting }: FormikHelpers<IAcknowledgementUpdate>) => {
            setBtnLoading(true)
            setError(null)
            try {
                values.title = `<div style="font-family: ${process.env.REACT_APP_CONTENT_FONT_FAMILY};font-size: ${process.env.REACT_APP_CONTENT_FONT_SIZE};">${values.title}</div>`;
                const data: any = await updateAcknowledgement(values);
                if (data.status) {
                    setBtnLoading(false)
                    setSubmitting(false);
                    toast.success('Acknowledgement updated successfully.')
                    navigate('/acknowledgement');
                } else {
                    navigate('/acknowledgement');
                    setSubmitting(false)
                    setBtnLoading(false)
                }
            } catch (error) {
                // toast.error('Failed to create acknowledgement. Please try again.')
                // setStatus('Failed to create acknowledgement')
                setSubmitting(false)
                setBtnLoading(false)
            }
        },
    })
    useEffect(() => {
        const fetchAcknowledgement = async (id) => {
            setLoading(true)
            try {
                const response: any = await getAcknowledgement(id);
                if (response) {
                    setValues({
                        id: response.data.id,
                        title: response.data.title,
                    });
                } else {
                    navigate('/acknowledgement');
                }
            } catch (error) {
                setLoading(false)
                // setError('Failed to fetch Acknowledgement details');
            } finally {
                setLoading(false)
            }
        };
        fetchAcknowledgement(id);
    }, [id]);
   
    return (
        <>
            {mode === 'dark' && (
                <style>{`
                    .ck.ck-editor__main>.ck-editor__editable{
                        background-color: #1C1C1C !important; 
                    }
                    `}
                </style>)
            }
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-target='#kt_acknowledgement_create'
                    aria-expanded='true'
                    aria-controls='kt_acknowledgement_create'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Edit Acknowledgement</h3>
                    </div>
                    <div className='card-title m-0'>
                        <Link to={"/acknowledgement"} className='btn btn-light'><i className="bi bi-arrow-left-short fs-1"></i>Back</Link>
                    </div>
                </div>
                <div id='kt_acknowledgement_create'>

                    <form onSubmit={handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                            {loading ? (<InputSkeleton rows={1} columns={1} colsWidth={12} height={200} />) :
                                <div className="row mb-6">
                                    <div className="col-lg-12 fv-row">
                                        <label className="required fw-bold fs-6 mb-2">Title</label>
                                    
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={values.title}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setFieldValue('title', data);
                                            }}
                                            onReady={(editor) => {
                                                editorRef.current = editor.ui.view.editable.element;
                                            }}
                                        />

                                        {touched.title && errors.title && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{errors.title}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                                {!btnloading && 'Update Acknowledgement'}
                                {btnloading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
}
export { AcknowledgementEdit }

