import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRoleWiseData } from "./core/_requests";

interface Role {
    role: string;
    title: string;
    count: number;
    description?: string[];
}

const initialRoleval:any = {
    role: "",
    title: "",
    count: "",
    description: []
}
const RolesList: FC = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [rolesList, setRolesList] = useState<Role[]>([
        {
            role:"hoa",
            title: "Super Admin",
            count: 1,
            description: [
                "Access Dashboard Data",
                "Manage All Requests",
                "Manage All Members",
                "Import All Members",
                "Manage Roles and Their Staff",
                "Manage Guidelines",
                "Manage Acknowledgements",
                "Manage All Member Properties"
            ]
        },
        {
            role:"admin_staff",
            title: "Admin/Staff",
            count: 0,
            description: [
                "Access Dashboard Data",
                "Manage All Requests",
                "Manage Members",
                "Import Members",
                "Manage Guidelines",
                "Manage Acknowledgments",
                "Manage Member Properties"
            ]
        },
        {
            role:"review_board_member",
            title: "Review Board Member",
            count: 0,
            description: [
                "Access only requests that are in the 'Ready for Board Review', 'Pre-Approved', & 'Discussion Needed' statuses",
                "Vote on a request to pre-approve it or set it for discussion",
                "View Guidelines"
            ]
        },
        {
            role:"board_of_directors",
            title: "Board Of Directors",
            count: 0,
            description: [
                "Access Dashboard Data",
                "Manage All Requests (Can't vote on a request)",
                "Manage Members",
                "Import Members",
                "Manage Guidelines",
                "Manage Acknowledgments",
                "Manage Member Properties"
            ]
        }
    ]);
    
    useEffect(() => {
        const fetchBoard = async () => {
            setLoading(true);
            try {
                const response: any = await getRoleWiseData();

                if (response) {
                    rolesList.forEach(role => {
                        if (response.data.hasOwnProperty(role.role)) {
                            role.count = response.data[role.role];
                        }
                    });
                }
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };
        fetchBoard();
    }, []);

    
    return (
        <>
        <style>{`
            .css-13cymwt-control {
              min-height: 45px !important; 
            }
            .css-1jqq78o-placeholder{
              font-size:14px !important;
            }
            `}
            </style>
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
            {rolesList.map((role:any, index) => (
                <div className="col-md-4" key={index}>
                    <div className="card card-flush h-md-100">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>{role.title}</h2>
                            </div>
                        </div>

                        <div className="card-body pt-1">
                            <div className="fw-bold text-gray-600 mb-5">
                                Total users with this role: {role.count}
                            </div>

                            <div className="d-flex flex-column text-gray-600">
                                {role.description.slice(0, 10).map((item, idx) => (
                                    <div className="d-flex align-items-center py-2" key={idx}>
                                        <span className="bullet bg-primary me-3"></span>
                                        {item}
                                    </div>
                                ))}
                                {role.description.length > 10 && (
                                    <div className="d-flex align-items-center py-2">
                                        <span className="bullet bg-primary me-3"></span>
                                        <em>and {role.description.length - 10} more...</em>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </>
    )
}
export { RolesList }