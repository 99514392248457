import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { IPropertyUpload } from './core/_models';
import { toast } from 'react-toastify';
import { fileUpload } from './core/_requests';
import clsx from 'clsx';
import Papa from 'papaparse';
import { KTIcon } from '../../../_metronic/helpers';

const propertyDetailsSchema = Yup.object().shape({
    // file: Yup.mixed<File>().required('xA file is required'),
    file: Yup.string().required('file is required'),
});

const PropertyUpload: React.FC = () => {
    const fileInputRef:any = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [successMsg, setSuccessMsg] = useState(null);
    const [invalidCount, setInvalidCount] = useState(0);
    const [invalidEmail, setInvalidEmail] = useState([]);
    const [duplicateCount, setDuplicateCount] = useState(0);
    const [duplicateEmail, setDuplicateEmail] = useState([]);


    const formik = useFormik({
        initialValues: {
            file: null,
        },
        validationSchema: propertyDetailsSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            if (values.file) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    if (e.target && typeof e.target.result === 'string') {
                        await parseAndUploadFile(e.target.result);
                        resetForm();
                    } else {
                        toast.error('FileReader result is not a string.');
                    }
                };
                reader.onerror = (error) => {
                    toast.error('File reading error:'+error);

                };
                reader.readAsText(values.file);
            }
            setSubmitting(false);
        },
    });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type !== 'text/csv') {
                toast.error('Please upload a CSV file.');
                formik.setFieldValue('file', null); // Clear the file input
                return;
            }
            formik.setFieldValue('file', file);
        }
    };

    const parseCSV = (content) => {
        return new Promise((resolve, reject) => {
            Papa.parse(content, {
                header: true,   
                skipEmptyLines: true,  
                complete: (results) => {
                    if (results.errors.length > 0) {
                        return reject(new Error('Error parsing CSV'));
                    }
                    const headers = results.meta.fields || [];
                    const rows = results.data;
                    resolve({ headers, rows });
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    };
    const parseAndUploadFile = async (content) => {
        setLoading(true);
        let propertyData;
        try {
            propertyData = await parseCSV(content);
            // Check if headers and rows are defined
            if (!propertyData.headers || !propertyData.rows) {
                toast.error(`Invalid CSV format`);                
            }
            const { headers, rows } = propertyData;

            try {
                const response:any = await fileUpload(rows);
                
                if(response.status){

                    let succMsg:any = `The file contains ${response.data.totalRecordCount} records, of which ${response.data.successCount} were successfully imported.`;

                    if(response.data.failedCount > 0){
                        succMsg = succMsg+` However, ${response.data.failedCount} records were found with invalid data`;
                    }
                    if(response.data.duplicateCount > 0 && response.data.failedCount > 0){
                        succMsg = succMsg+`, and ${response.data.duplicateCount} records had email addresses that already exist in the system.`;
                    }else if(response.data.duplicateCount > 0){
                        succMsg = succMsg+` However, ${response.data.duplicateCount} records had email addresses that already exist in the system.`;
                    }

                    if(response.data.duplicateCount > 0 && response.data.failedCount > 0){
                        succMsg = succMsg+` Please find the duplicate and invalid email addresses from the list below`;
                    }  
                    setSuccessMsg(succMsg)
                    setInvalidCount(response.data.failedCount)
                    setInvalidEmail(response.data.failedRecordEmail)
                    setDuplicateCount(response.data.duplicateCount)
                    setDuplicateEmail(response.data.duplicateRecordEmail)
                    toast.success(`Properties uploaded successfully`);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = null; // Clear the file input
                    }
                }
            } catch (error) {
                toast.error('Failed to upload properties. Please try again.');
            }
        } catch (error) {
            toast.error('Failed to parse CSV file. Please check the file format.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            {successMsg &&
            <div className='alert alert-dismissible notice d-flex bg-light-success rounded border-success border border-dashed mb-9 p-6'>
                <KTIcon iconName='shield-tick' className='fs-2tx text-success me-4' />
                <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                    <div className='mb-3 mb-md-0 fw-bold'>
                        <h4 className='text-gray-800 fw-bolder'>CSV file imported successfully.</h4>
                        <div className='fs-6 text-gray-600 pe-7'>
                            {successMsg}
                        </div>
                    </div>
                </div>
                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                    <span className="svg-icon svg-icon-2x svg-icon-dark" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg></span>
                </button>
            </div>}
            
            {duplicateCount > 0 &&
            <div className='alert alert-dismissible notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />

                <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                    <h4 className='text-gray-800 fw-bolder'>Duplicate email addresses found in the CSV file.</h4>
                    <div className='fs-6 text-gray-600'>
                        <ul>
                            {duplicateEmail.map((email, index) => (
                            <li key={index}>{email}</li>
                            ))}
                        </ul>
                    </div>
                    </div>
                </div>
                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                    <span className="svg-icon svg-icon-2x svg-icon-dark" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg></span>
                </button>
            </div>}
            
            {invalidCount > 0 &&
            <div className='alert alert-dismissible notice d-flex bg-light-danger rounded border-danger border border-dashed mb-9 p-6'>
                <KTIcon iconName='information-5' className='fs-2tx text-danger me-4' />

                <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                    <h4 className='text-gray-800 fw-bolder'>Data invalid found in the CSV file.</h4>
                    <div className='fs-6 text-gray-600'>
                        <ul>
                            {invalidEmail.map((email, index) => (
                            <li key={index}>{email}</li>
                            ))}
                        </ul>
                    </div>
                    </div>
                </div>
                <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                    <span className="svg-icon svg-icon-2x svg-icon-dark" ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg></span>
                </button>
            </div>}
            
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0 cursor-pointer' role='button' data-bs-target='#kt_account_profile_details' aria-expanded='true' aria-controls='kt_account_profile_details'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Import Property</h3>
                    </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                    <form onSubmit={formik.handleSubmit} className='form'>
                        <div className='card-body border-top p-9'>
                            <div className="row mb-6">
                                <div className="col-lg-6 fv-row">
                                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Upload CSV File</label>
                                    <input
                                        type='file'
                                        ref={fileInputRef}
                                        className={clsx(
                                            'form-control form-control-lg  mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.file && formik.errors.file},
                                            {
                                            'is-valid': formik.touched.file && !formik.errors.file,
                                            }
                                        )}
                                        accept='.csv,.xlsx'
                                        onChange={handleFileChange}
                                    />
                                    {formik.touched.file && formik.errors.file && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.file}</div>
                                        </div>
                                    )}
                                    
                                    <div className="mt-3"><a className="text-primary  fw-bold fs-6 me-4">Download our CSV template to make sure your CSV formatted correctly.</a><a href="/csv/property_sample_csv.csv" className="btn btn-icon btn-bg-primary btn-sm me-1" download>
                                        {/* <i className="ki-duotone ki-arrow-down fs-2"><span className="path1"></span><span className="path2"></span></i> */}
                                        <i className="bi bi-download fs-2 text-white"></i>
                                    </a></div>
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || !formik.isValid}>
                            {!loading && 'Save Changes'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export { PropertyUpload };
